.basket-price {
    display: flex;
    justify-content: space-between;
    grid-column-gap: 30px;
    align-items: flex-end;

    &__inputs, &__total {
        width: 100%;
    }

    .app-input {
        position: relative;

        .app-button {
            position: absolute;
            right: 0;
            bottom: 14px;
            max-height: 25px;
            max-width: 105px;
            padding: 4px 15px;
        }
    }

    &__delivery, &__reward, &__result {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        grid-column-gap: 4px;
        margin-bottom: 14px;
    }

    &__delivery {
        margin-top: 30px;

        &-title, &-count {
            font-size: 16px;
            line-height: 19px;
            color: var(--main-font-color);
            font-weight: 400;
            margin: 0;
        }
    }

    &__reward {

        &-title {
            font-weight: 400;
            font-size: 16px;
            color: var(--main-font-color);
            line-height: 17px;
            margin: 0;
        }

        &-count {
            border-radius: 25px;
            background-color: var(--main-aprove-color);
            color: var(--main-font-secondary-color);
            padding: 4px 15px;
            min-width: 54px;
            min-height: 25px;
            box-sizing: border-box;
            text-align: center;
            font-size: 14px;
            line-height: 17px;
            margin: 0;
        }
    }

    &__result {
        margin-bottom: 0;

        &-title, &-count {
            font-size: 16px;
            color: var(--main-font-color);
            font-weight: 700;
            line-height: 19px;
            margin: 0;
        }
    }
}
