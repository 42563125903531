.agreement {

    &__title {
        font-size: 32px;
        color: var(--main-font-color);
        line-height: 39px;
        font-weight: 700;
    }

    &__card {
        margin: 30px 0 50px;
        background: var(--card-bg-color);
        border-radius: var(--border-radius-small);
        padding: 50px 40px;
    }

    &__text {
        font-size: 16px;
        font-weight: 400;
        color: var(--main-font-color);
        line-height: 19px;
        text-align: center;
    }

    @media (max-width: 767px) {

        &__card {
            padding: 50px 20px;
        }

        &__title {
            font-size: 24px;
            color: var(--main-font-color);
            line-height: 29px;
        }
    }
}
