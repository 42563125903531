.autocomplete {

    &__prompt {

        &-list {
            z-index: 1;
            position: absolute;
            top: 75px;
            left: 0;
            right: 0;
            border-radius: 15px;
            border: var(--main-font-color) 1px solid;
            background: var(--main-card-bg-color);
            padding: 20px 15px;
        }

        &-item {
            line-height: 19px;
            font-size: 16px;
            font-weight: 400;
            border-radius: 25px;
            cursor: pointer;
            padding: 10px 0 10px 20px;
            transition: .4s ease;

            &:hover {
                background-color: var(--main-bg-primary-color);
                color: var(--main-font-color);
            }
        }

        &-message {
            position: absolute;
            bottom: -15px;
            line-height: 1;
            font-size: 12px;
            font-weight: 400;
            color: var(--main-font-color);
            margin-left: 12px;
        }
    }
}
