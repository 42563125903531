@import '../../css/variables.css';

/* twig views */
@import '../../../app/Resources/views/default-new/checkout/styles.scss';
@import '../../../app/Resources/views/default-new/checkout/client-data/styles.scss';
@import '../../../app/Resources/views/default-new/checkout/order-info/styles.scss';
@import '../../../app/Resources/views/default-new/checkout/basket-price/styles.scss';
@import '../../../app/Resources/views/default-new/layout/header/header/styles.scss';
@import '../../../app/Resources/views/default-new/profile/account/styles.scss';
@import '../../../app/Resources/views/default-new/profile/bonus/styles.scss';
@import '../../../app/Resources/views/default-new/profile/order/styles.scss';
@import '../../../app/Resources/views/default-new/profile/orders/styles.scss';
@import '../../../app/Resources/views/default-new/profile/styles.scss';
@import '../../../app/Resources/views/default-new/feedback/styles.scss';
@import '../../../app/Resources/views/default-new/contacts/styles.scss';
@import '../../../app/Resources/views/default-new/sidebar-bodies/left-sidebar/styles.scss';
@import '../../../app/Resources/views/default-new/agreement/styles.scss';
@import '../../../app/Resources/views/default-new/contacts/styles.scss';
@import '../../../app/Resources/views/default-new/delivery/styles.scss';

/* Vue ui */
@import '../../../front/src/ui/app-input/styles.scss';
@import '../../../front/src/ui/app-button/styles.scss';
@import '../../../front/src/ui/app-counter/styles.scss';
@import '../../../front/src/ui/app-radio/styles.scss';
@import '../../../front/src/ui/app-textarea/styles.scss';
@import '../../../front/src/ui/app-checkbox/styles.scss';
@import '../../../front/src/ui/app-select/styles.scss';
@import '../../../front/src/ui/app-auth-code/styles.scss';
@import '../../../front/src/ui/app-loader/styles.scss';
@import '../../../front/src/ui/app-progress-button/styles.scss';
@import '../../../front/src/ui/app-card-button/styles.scss';
@import '../../../front/src/ui/app-datepicker/styles.scss';
@import '../../../front/src/ui/app-radio-tape/styles.scss';
@import '../../../front/src/ui/app-autocomplete/styles.scss';
@import '../../../front/src/ui/app-product-slider/styles.scss';
@import '../../../front/src/ui/app-sidebar/styles.scss';

/* Vue ui modals */
@import '../../../front/src/ui/app-modals/init-modal/styles.scss';
@import '../../../front/src/ui/app-modals/auth-modal/styles.scss';
@import '../../../front/src/ui/app-modals/approve-modal/styles.scss';
@import '../../../front/src/ui/app-modals/product-modal/styles.scss';
@import '../../../front/src/ui/app-modals/address-modal/styles.scss';

:root {
    /* все переменные вынесены в файл variables.css */
}

/* default sets */
html,
body {
    position: relative;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    background: var(--main-bg-color);
}

h1 {
    margin: 0;
}

.wrapper {
    min-height: 100vh;

    &.is-blocked {
        position: fixed;
        inset: 0;
    }
}

.content {
    display: flex;
    flex-direction: column;
    min-height: inherit;
}

.footer {
    margin-top: auto;
}

main {
    min-height: 500px;
    margin-top: 24px;
    margin-bottom: 72px;

    @media (max-width: 767px) {
        margin-top: 50px;
    }
}

button {
    background: none;
    border: none;
}

h1, h2, h3, h4, h5, h6, p {
    color: var(--main-font-color);
}

button, a {
    cursor: pointer;
}

.hide {
    display: none !important;
}

/* special container addon to flexboxgrid2 class */
@media only screen and (min-width: 1440px) {
    .container {
        width: calc(1440px - 16px);
        max-width: 100%;
    }
}

.body-hide {
    overflow: hidden !important;
    position: relative !important;
    padding-left: 0px;
    padding-top: 0px;
    padding-right: 0px;
    margin-left: 0;
    margin-top: 0;
    margin-right: 0px !important;
}

/* запретить перенос на новую строку */
.no-line-break {
    white-space: nowrap;
}

/* a11y class for screenreaders */
.show-for-sr {
    position: absolute !important;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    border: 0;
}

/* Общие стили для полосы прокрутки */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;

    &-thumb {
        background-color: var(--main-default-button-color);
        border-radius: 5px;

        &:hover {
            background-color: var(--main-active-button-color);
        }
    }

    &-track {
        background-color: var(--main-secondary-color);
    }
}

/* breadcrumbs  START */
/* мы от них решили отказаться не навсегда, а до тех пор пока не придумаем как визуально их сделать лучше */
/* .breadcrumbs {
    display: flex;
    flex-flow: row nowrap;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    font-size: 10px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.breadcrumbs__link {
    color: var(--breadcrumbs-main-color);
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.breadcrumbs__link_active {
    color: var(--breadcrumbs-active-color);
}

.breadcrumbs__divider {
    color: var(--breadcrumbs-active-color);

    &:before {
        content: var(--breadcrumbs-divider);
    }
}*/
/* breadcrumbs END*/

/* ***** NAV DRAWER (sidebar) START ***** */
.nav-drawer {
    position: fixed;
    z-index: 51;

    &.is-open {
        inset: 0;

        .nav-drawer__overlay {
            height: 100%;
            width: 100%;
            background: var(--main-overlay-color);
        }

        .nav-drawer__sidebar {
            transform: translateX(0);
        }
    }

    &__overlay {
        cursor: pointer;
        transition: .3s ease;
        background-color: transparent;
        z-index: -1;
    }

    &__sidebar {
        max-width: 400px;
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        transform: translateX(-100vw);
        transition: .6s ease;
    }
}
/* ***** NAV DRAWER (sidebar) END ***** */

/* ***** CART DRAWER (sidebar) START ***** */
.cart-drawer {
    position: fixed;
    z-index: 51;

    &.is-open {
        inset: 0;
        height: 100vh;
        width: 100vw;

        .cart-drawer__overlay {
            height: 100%;
            width: 100%;
            background: var(--main-overlay-color);
        }

        .cart-drawer__sidebar {
            transform: translateX(0);
        }
    }

    &__overlay {
        cursor: pointer;
        transition: .3s ease;
        background-color: transparent;
        z-index: -1;
    }

    &__sidebar {
        display: flex;
        grid-column-gap: 19px;
        max-width: 467px;
        width: 100%;
        height: 100%;
        background-color: var(--sidebar-bg-color);
        transition: .6s ease;

        &-cross {
            position: absolute;
            left: -49px;
            top: 21px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            width: 30px;

            img {
                height: 100%;
                width: 100%;
            }

            @media (max-width: 570px) {
                display: none;
            }
        }
    }
}

.sidebar-cart {
    position: relative;
    display: flex;
    flex-direction: column;
    grid-row-gap: 30px;
    width: 100%;

    &__title {
        display: flex;
        align-items: center;
        grid-column-gap: 10px;
        background-color: var(--sidebar-bg-color);
        padding: 20px 26px;

        @media (max-width: 570px) {
            padding: 20px 12px;
        }

        &-btn {
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: rotate(90deg);

            img {
                height: 100%;
                width: 100%;
            }

            @media (min-width: 571px) {
                display: none;
            }
        }

        &-text {
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
            margin: 0;
        }
    }

    &__empty-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        grid-row-gap: 10px;
        height: 100%;

        &-img {
            height: 100px;
            width: 100px;

            img {
                width: 100%;
                height: auto;
            }
        }

        &-title {
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
            color: var(--basket-empty-body-title-color);
            margin: 10px 0 0;
        }

        &-subtitle {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: var(--basket-empty-body-subtitle-color);
            margin: 0;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        grid-row-gap: 30px;
        height: 100%;
        overflow-y: auto;
        padding: 0 26px;

        @media (max-width: 400px) {
            padding: 0 12px;
        }

        &-list {
            display: flex;
            flex-direction: column;
            grid-row-gap: 29px;

            .cart-item {
                display: flex;
                grid-column-gap: 20px;

                &__text {

                    &_main {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        color: var(--sidebar-main-text-color);
                        margin: 0;
                    }

                    &_secondary {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        color: var(--basket-item-description-color);
                        margin: 0;
                    }
                }

                &__img {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: var(--placeholder-color);
                    height: 88px;
                    width: 88px;

                    img, .dropdown-card__img--none {
                        height: 88px;
                        width: 88px;
                    }
                }

                &__info {
                    display: flex;
                    flex-direction: column;
                    grid-row-gap: 8px;
                    width: 100%;

                    &-header {
                        display: flex;
                        justify-content: space-between;
                        grid-column-gap: 8px;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;

                        &-btn {
                            align-self: flex-start;
                            color: var(--sidebar-main-text-color);
                            cursor: pointer;
                        }
                    }

                    &-modifiers {
                        text-transform: lowercase;
                    }

                    &-result {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        grid-column-gap: 4px;

                        .app-counter {
                            height: 35px;
                        }
                    }
                }
            }
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        grid-row-gap: 10px;
        padding: 0 12px 30px 12px;

        &-delivery {
            display: flex;
            align-items: center;
            justify-content: space-between;
            grid-column-gap: 4px;

            &-text {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: var(--basket-font-color);
                margin: 0;
            }
        }

        &-result {
            display: flex;
            align-items: center;
            justify-content: space-between;
            grid-column-gap: 4px;

            &-text {
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                color: var(--basket-font-color);
                margin: 0;
            }
        }

        &-link {
            display: block;
            max-width: 326px;
            width: 100%;
            margin: 0 auto;

            .app-button {
                width: 100%;
                height: 50px;
            }
        }
    }
}
/* ***** CART DRAWER (sidebar) END ***** */

.sidebarjs--left {
    height: 100%;
}

/* custom checkbox */
.checkbox {
    display: none;

    & + label {
        width: 17px;
        height: 17px;
        border: var(--border) var(--checkbox-color);
        border-radius: 4px;
        background: inherit;
        margin-right: 6px;
        display: inline-block;
        position: relative;
    }

    & + label:active {
        background: color-mod(var(--checkbox-color) lightness(90%));
    }

    & + label:hover {
        background: color-mod(var(--checkbox-color) lightness(90%));
    }

    &:checked + label {
        background: color-mod(var(--checkbox-color) lightness(90%));
        border: var(--border) var(--checkbox-color);
        color: var(--checkbox-color);
    }

    &:checked + label:after {
        content: "✔";
        font-size: 13px;
        position: absolute;
        top: 1px;
        left: 2px;
        color: var(--checkbox-color);
    }
}

/* base */
.nav__locale-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 1100px;
    transform: translate(-50%, -50%);
    background: var(--main-card-bg-color);
    z-index: 40;
    visibility: hidden;
    opacity: 0;
    transition: 1s;
    @media (max-width: 1100px) {
        width: 85%;
    }
}

.nav__locale-modal-auth {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 1100px;
    transform: translate(-50%, -50%);
    background: var(--main-card-bg-color);
    z-index: 40;
    visibility: hidden;
    opacity: 0;
    transition: 1s;
    border-radius: var(--border-radius-small);
    @media (max-width: 1100px) {
        width: 85%;
    }
}

.nav__locale-modal__visible {
    visibility: visible;
    opacity: 1;
}

.nav__locale-close {
    position: fixed;
    top: 45px;
    left: 87%;
    color: var(--main-secondary-color);
    visibility: hidden;
    opacity: 0;
    transition: 1s;
    font-size: 40px;
    cursor: pointer;
    z-index: 40;
}

.nav__locale-close__visible {
    visibility: visible;
    opacity: 1;
}

.nav__locale-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--main-overlay-color);
    z-index: 39;
    visibility: hidden;
    opacity: 0;
    transition: 1s;
}

.nav__locale-background__visible {
    visibility: visible;
    opacity: 1;
}

.nav__profile button {
    display: flex;
    align-items: center;
    min-width: 172px;
    min-height: 40px;
    font-weight: 500;
    line-height: 19px;
    font-size: 16px;
    text-align: center;
    background: none;
    color: #333333;

    &:hover {
        color: #F7967D;
        cursor: pointer;
    }

    &:active {
        color: #9B2708;
    }

    &:disabled {
        color: #8B8B8B;
        cursor: not-allowed;
    }

    a {
        text-decoration: none;
        color: var(--main-font-color);
    }
}

.nav__search-wrapper {
    display: flex;
    flex-grow: 0.8;
    width: 35px;
    position: absolute;
    right: 170px;
    transition: width .5s ease;

    & form {
        width: 100%;
    }

    &:hover,
    &:focus-within {
        width: 300px;
        transition: width .5s ease;

        & .nav__search {
            & input {
                width: 90%;
                transition: width .5s ease;
            }
        }
    }
}

.nav__search-wrapper--no-basket {
    right: 25px;
}

.nav__search {
    position: relative;
    box-sizing: border-box;
    border-radius: 200px;
    padding: 2px 7px;
    background: none;
    color: var(--main-font-color);
}

.nav__search input {
    border: none;
    outline: none;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: var(--main-font-color);
    background: none;
    width: 1px;
    transition: width .5s ease;
}

.nav__search input::placeholder {
    color: color-mod(var(--header-search-font-color) lightness(60%));
    border-bottom: 1px solid color-mod(var(--header-search-font-color) lightness(60%));
}

.nav__search label {
    position: absolute;
    top: -10px;
    padding: 0 2px 0 2px;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    font-size: 12px;
    letter-spacing: 0.4px;
    text-transform: capitalize;
    color: var(--header-item-color);
    background: var(--header-bg-color);
}

.nav__search button {
    position: absolute;
    right: 7px;
    top: 50%;
    border: none;
    background: none;
    color: var(--header-search-font-color);
    opacity: 0.6;
    padding: 0;
    transform: translateY(-50%);

    &:before {
        font-size: 19px;
    }
}

.nav__order-status {
    text-decoration: none;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    background: none;
    color: var(--header-item-color);
    border-radius: 50%;
}

.nav__order-status.mdi:before {
    font-size: 40px;
}

.nav__order-status:hover {
    color: color-mod(var(--header-item-color) blackness(+10%));
}

.dropmic--show .nav__cart {
    background: var(--main-error-color);
}

/* стили дропдауна */

.nav__cart-dropdown {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    z-index: 25;
    border: none;
    background: var(--main-card-bg-color);
    overflow-y: scroll;
    /*position: fixed;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;*/
    max-width: 400px;
    max-height: none;
    /*transform: none !important;*/
    height: 100%;
}

/* модалка промокода */
.promocode-modal, .paid-by-bonuses-modal, {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: none;
}

.promocode-modal__background, .paid-by-bonuses-modal__background {
    position: relative;
    height: 100%;
    background: var(--main-shadow-basic-color);
    display: none;
}

.promocode-modal__main, .paid-by-bonuses-modal__main {
    position: absolute;
    bottom: -268px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: 0;
    background: var(--main-card-bg-color);
    border-radius: var(--border-radius-small) var(--border-radius-small) 0 0;
    padding: 0;
    transition: .5s;
    overflow: hidden;
    box-sizing: border-box;
}

.promocode-modal__header, .paid-by-bonuses-modal__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.promocode-modal__header-title, .paid-by-bonuses-modal__header-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
}

.promocode-modal__input, .paid-by-bonuses-modal__input {
    border: none;
    background: none;
    width: 100%;
    border-bottom: 1px solid var(--main-border-color);
    padding: 10px 0;

    &:focus {
        border: none;
        outline: none;
        border-bottom: 1px solid var(--main-font-primary-color);
    }
}

.promocode-modal__button, .paid-by-bonuses-modal__button {
    text-decoration: none;
    border-radius: var(--border-radius-large);
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: none;
    font-weight: 500;
    line-height: 19px;
    font-size: 16px;
    text-align: center;
    background: var(--button-bg-color);
    color: var(--button-font-color);
    width: 305px;
    height: 50px;

    &:hover {
        color: var(--button-font-hovered-color);
        background: var(--button-bg-hovered-color);
        cursor: pointer;
        border: var(--border) var(--button-bg-color);
    }

    &:active {
        background: var(--button-bg-active-color);
        color: var(--button-font-color);
        cursor: pointer;
    }
}

.paid-by-bonuses-modal__description {
    align-self: flex-start;
    color: var(--main-font-color);
    font-size: 14px;
}

/* это хак, починить стили дропдаун плагина */
.dropmic--show::after,
.dropmic--show::before {
    display: none;
}

.dropdown-card__left {
    padding-right: 20px;
}

.dropdown-card__header {
    display: flex;
    align-items: start;
    justify-content: space-between;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: var(--main-font-color);
    text-transform: none;
    padding: 0 0 10px 0;
}

.dropdown-card__img--none::before {
    content: '\f82a';
    color: var(--main-secondary-color);
    padding: 0 5px;
    display: inline-block;
    font: normal normal normal 12px/1 "Material Design Icons";
    font-size: 35px;
    text-rendering: auto;
    line-height: 24px;
}

.dropdown-card__dismiss {
    color: var(--main-font-color);
    cursor: pointer;
}

.dropdown-card__button {
    text-decoration: none;
    border-radius: var(--border-radius-large);
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: none;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    font-size: 16px;
    text-align: center;
    background: var(--button-bg-color);
    color: var(--button-font-color);
    padding: 16px 8px;
    width: 100%;
    max-width: 305px;

    &:hover {
        color: var(--button-font-hovered-color);
        background: var(--button-bg-hovered-color);
        cursor: pointer;
        border: var(--border) var(--button-bg-color);
    }

    &:active {
        background: var(--button-bg-active-color);
        color: var(--button-font-color);
        cursor: pointer;
    }

    &:disabled {
        background: var(--button-bg-disabled-color);
        color: var(--button-font-disabled-color);
        cursor: not-allowed;
    }
}

.info-title {
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    font-size: 10px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    padding: 0 0 5px 0;
}

.nav__cart-dropdown-button {
    text-decoration: none;
    display: inline-block;
    border: none;
    border-radius: 200px;
    padding: 6px 16px 6px 16px;
    margin: 0;
    outline: none;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    background: var(--dropdown-card-button-bg-color);
    color: var(--dropdown-card-button-font-color);
}

.nav__cart-dropdown-button:hover {
    background: color-mod(var(--dropdown-card-button-bg-color) blackness(+10%));
}

.nav__cart-dropdown_empty {
    display: flex;
    justify-content: center;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    font-size: 14px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

/* конец стилей дропдауна */

@media (max-width: 768px) {

    .header .container {
        width: 100%;
        max-width: 100%;
        margin: 0;
        padding: 0;
    }

    .nav-mobile {
        position: fixed;
        width: calc(100% - 24px);
        height: 69px;
        padding: 0 12px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        background-color: var(--mobile-header-bg-color);
        backdrop-filter: blur(18px);
        top: 0;
    }

    [sidebarjs-container] {
        width: 100%;
        max-width: 390px;
    }
    .nav-mobile__sidebar {
        background-color: var(--main-bg-color);
        color: var(--sidebar-item-color);
        display: flex;
        flex-flow: column nowrap;
        overflow: hidden;
        overflow-y: scroll;
        height: 100%;
    }

    .nav-mobile__sidebar-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        height: 70px;
        background: var(--main-bg-color);
    }

    .nav-mobile__sidebar-item span,
    .nav-mobile__sidebar-item a {
        text-decoration: none;
        width: 100%;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: var(--main-footer-background-color);
    }

    .nav-mobile__sidebar-item span {
        width: auto;
    }

    .nav-mobile__sidebar-item span.nav-mobile__sidebar-item-subscript {
        margin-top: 5px;
        font-size: 12px;
        font-weight: 300;
    }

    .nav-mobile__sidebar-item a.nav-mobile__sidebar-item-company-name {
        font-size: 32px;
        line-height: 1.2;
        font-weight: 700;
    }

    .nav__sidebar-item a:hover {
        outline: 2px solid inherit;
        outline-offset: 2px;
    }

    .nav-mobile__sidebar-item-separator ~ .nav-mobile__sidebar-item {
        padding-left: 36px;
    }

    .nav-mobile__sidebar-item-separator {
        margin: 0 12px;
        width: calc(100% - 24px);
        height: 1px;
        background-color: var(--main-border-color);
    }

    .sidebar-item__company-name {
        padding-bottom: 0;
    }

    .sidebar-item__points {
        display: flex;
        justify-content: space-between;
    }

    .sidebar-item__phone {
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 0;
    }

    .nav-mobile__sidebar-close {
        color: var(--main-error-color);
        font-size: 24px;
    }

    .nav-mobile__sidebar-actions {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
    }

    .nav-mobile__logo-img {
        height: 34px;
    }

    .nav-mobile__search-wrapper {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        border: var(--border) var(--mobile-header-search-border-color);
        box-sizing: border-box;
        border-radius: var(--border-radius-large);
        padding: 4px 6px 4px 16px;
        background: var(--mobile-header-search-bg-color);
        color: var(--mobile-header-search-font-color);

        input {
            background: var(--mobile-header-search-bg-color);
        }
    }

    .nav-mobile__search-wrapper input {
        border: none;
        outline: none;
        font-style: normal;
        font-weight: normal;
        line-height: 20px;
        font-size: 14px;
        letter-spacing: 0.25px;
        width: 100%;
    }

    .nav-mobile__search-wrapper input::placeholder {
        color: color-mod(var(--mobile-header-search-font-color) lightness(60%));
    }

    .nav-mobile__search-wrapper label {
        position: absolute;
        top: -10px;
        padding: 0 2px 0 2px;
        font-style: normal;
        font-weight: normal;
        line-height: 16px;
        font-size: 12px;
        letter-spacing: 0.4px;
        text-transform: capitalize;
        color: var(--mobile-header-item-color);
        background: var(--mobile-header-bg-color);
    }

    .nav-mobile__search-wrapper button {
        border: none;
        background: none;
        color: var(--mobile-header-search-font-color);
        align-self: center;
    }

    .nav-mobile__search-form-md {
        flex-grow: 0.6;
        @media (max-width: 575px) {
            display: none;
        }
    }

    .nav-mobile__search-form-xs {
        width: 34px;
        height: 32px;
        background: var(--mobile-header-bg-color);
        border-radius: 50%;
        margin-left: auto;
        position: relative;
        display: inline-flex;

        @media (min-width: 576px) {
            display: none;
        }
    }

    .nav-mobile__search-button-xs {
        display: inline-block;
        border: none;
        padding: 8px;
        padding-bottom: 0;
        outline: none;
        line-height: 14px;
        text-align: center;
        background: var(--mobile-header-bg-color);
        color: var(--mobile-header-item-color);

        &:hover {
            cursor: pointer;
            /*background: color-mod(var(--mobile-header-bg-color) blackness(+10%));*/
        }
    }

    .nav-mobile__search-panel-xs {
        box-sizing: border-box;
        visibility: visible;
        position: absolute;
        padding: 15px 12px;
        border: none;
        max-width: 110vw;
        width: 110vw;
        right: -75px;
        top: 30px;
        background: var(--mobile-header-bg-color);
        display: flex;
        justify-content: flex-end;
        height: 75px;

        form {
            width: 63%;
            margin-right: 60px;

            input {
                background: var(--mobile-header-bg-color);
            }
        }

        & .nav-mobile__search-wrapper {
            border: var(--border) var(--mobile-header-search-border-color);
            background: var(--mobile-header-search-bg-color);
            color: var(--mobile-header-search-font-color);
            height: 100%;
        }

        &.hidden {
            visibility: hidden;
            height: 0;
        }
    }
}

.nav-mobile__sidebar {

    .app-accordion {
        margin: 20px 36px;

        &__btn {
            padding-left: 0;

            &-text {
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: var(--main-font-color);
            }
        }

        &__content {
            padding: 0;
            margin-left: 20px;
        }

        &__link {
            width: fit-content;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: var(--main-font-color);
            text-decoration: none;
            transition: .4s ease;

            &:hover {
                font-weight: 700;
            }
        }
    }
}
/* mobile-cart-button */
.mobile-cart-button {
    display: none;
    position: fixed;
    right: 12px;
    bottom: 15px;
    min-width: 120px;
    z-index: 23;

    @media (max-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: center;
        grid-column-gap: 4px;
    }
}

.nav-mobile__cart {
    position: relative;
    text-decoration: none;
    border-radius: 200px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-width: 100px;
    border: none;
    padding: 8px 16px 8px 16px;
    outline: none;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    background: var(--main-footer-title-color); /*var(--mobile-header-item-color);*/
    color: var(--main-bg-color);

    @media (max-width: 424px) {
        justify-content: space-between;
        min-width: 50px;
        min-height: 18px;
    }

    &:hover {
        background: color-mod(var(--mobile-header-item-color) blackness(+10%));
    }
}

.nav-mobile__cart-sum {
    font-size: 18px;
}

.nav-mobile__cart-list {
    display: flex;
    flex-flow: column nowrap;
    padding: 8px 20px 0 20px;
    background: var(--main-bg-color);
    overflow-y: scroll;
    height: 75%;
}

.mobile-cart-item {
    display: flex;
    align-items: start;
    justify-content: space-between;
    padding: 0 0 8px 0;
    color: var(--mobile-cart-item-color);
}

.mobile-cart-item:not(:first-child) {
    padding: 8px 0;
}

.mobile-cart-item img {
    width: 100%;
}

.mobile-cart-item__left {
    width: 30%;
}

.mobile-cart-item__right {
    width: 65%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.mobile-cart-item__header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
}

.mobile-cart-item__title {
    display: inline-block;
    color: var(--main-footer-background-color);
}

.mobile-cart-item__delete {
    color: var(--main-footer-background-color);
    cursor: pointer;
}

.mobile-cart-item__description {
    margin: 8px 0;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--main-shadow-basic-color);
}

.mobile-cart-item__options-block {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 8px 0;
    width: 100%;
}

.mobile-cart-item__option {
    display: flex;
    flex-flow: column nowrap;
}

.mobile-cart-item__option-title {
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    font-size: 10px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: inherit;
}

.mobile-cart-item__option-price {
    padding: 4px 0;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    font-size: 14px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    color: inherit;
}

.mobile-cart-item__option-quantity {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 20px;
    width: 88px;
    height: 29px;
    border: var(--border) var(--main-shadow-basic-color);
    border-radius: 35px;
    color: var(--main-footer-background-color);

    & button {
        background: none;
        color: var(--main-footer-title-color);
    }
}

.option-quantity__count {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: var(--main-footer-background-color);
}

.mobile-cart__button {
    position: sticky;
    bottom: 0;
    text-decoration: none;
    display: inline-block;
    border: none;
    border-radius: var(--border-radius-large);
    outline: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    background: var(--main-footer-title-color);
    color: var(--main-bg-color);
    padding: 15px 0;
}

.mobile-cart__button:hover {
    background: color-mod(var(--sidebar-button-bg-color) blackness(+10%));
}

.nav-mobile__cart-empty {
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    font-size: 14px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    color: inherit;
}

/* footer START */
.footer {
    background-color: var(--footer-bg-color);
    padding-top: 50px;

    &__title {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: var(--footer-title-color);
        margin: 0 0 15px;
    }

    &__link {
        font-size: 16px;
        line-height: 19px;
        text-decoration: none;
        color: var(--footer-text-color);
        width: fit-content;
    }

    &__text {
        font-size: 16px;
        line-height: 19px;
        color: var(--footer-text-color);
        margin: 0;
        font-weight: 400;
        text-decoration: none;
        width: fit-content;

        &_bold {
            font-weight: 700;
        }
    }

    &__lists {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
        grid-column-gap: 8px;
        grid-row-gap: 40px;
        padding-bottom: 40px;

        &-item {
            display: flex;
            flex-direction: column;
            grid-row-gap: 15px;

            &_inner {
                display: flex;
                flex-direction: column;
                grid-row-gap: 15px;
                margin-top: 15px;
            }
        }
    }

    &__download-btn {
        width: 180px;
        height: 52px;

        img {
            width: 100%;
        }
    }

    &__separator-line {
        height: 1px;
        width: 100%;
        background-color: var(--footer-separator-line-color);
    }

    &__copyright {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        grid-gap: 8px;
        padding-top: 20px;
        padding-bottom: 20px;

        &-link, &-text {
            font-size: 12px;
            line-height: 15px;
            color: var(--footer-text-color);
            margin: 0;
        }

        &-link {
            text-decoration: none;
        }
    }
}
/* footer END */

/* pages */

/* auth */
.auth {
    padding: 55px 20px 55px 20px;
}

.auth-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.auth-card {
    width: 100%;
    display: flex;
    background: var(--main-card-bg-color);
    border-radius: var(--border-radius-small);
    justify-content: center;
}

.auth-card__block_title {
    font-size: 32px;
    font-weight: 500;
    margin-top: 40px;
    @media (max-width: 600px) {
        margin-top: 0;
    }
}

.auth-card__block {
    @media (max-width: 550px) {
        width: 100%;
    }
}

.auth-card__block:first-child {
    @media (max-width: 1028px) {
        display: none;
    }
}

.auth-card__block_input {
    margin-top: 40px;
    @media (max-width: 550px) {
        text-align: center;
    }
}

.auth_card__block_input_tittle {
    width: 300px;
    font-size: 16px;
    color: var(--main-border-color);
    margin-bottom: 10px;
}

.auth-card__block_input_repeat_code {
    font-size: 13px;
    margin-top: -10px;
    cursor: pointer;
}

.auth-card__block_input_change_phone {
    font-size: 13px;
    margin-top: 0;
    cursor: pointer;
}

.auth-card__block_input_change_phone:hover, .auth-card__block_input_repeat_code:hover {
    color: var(--footer-title-color)
}


.auth-card__block_personal_data {
    word-wrap: break-word;
    max-width: 300px;
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
    line-height: 14.52px;
    font-weight: 400;
    color: var(--main-border-color);

    a {
        color: var(--main-font-color);
        line-height: 15px;
        font-weight: 400;
        text-decoration: none;
    }

    @media (max-width: 550px) {
        max-width: none;
    }
}

/* about */
.about {
    padding: 20px 20px 55px 20px;
}

.feedback {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 20px 0 0 0;
    margin: 0 auto;
    max-width: 750px;
}

.feedback__button {
    display: inline-block;
    border: none;
    border-radius: 200px;
    padding: 8px 16px 8px 16px;
    outline: none;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    font-size: 20px;
    text-align: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    background: var(--card-action-primary-color);
    color: var(--card-action-secondary-color);
    margin: 0 0 20px 0;
}

/* article and articles */

.article {
    padding: 20px;
}

.articles {
    padding: 20px;
}

.articles__title {
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 34px;
    letter-spacing: 0.25px;
    color: var(--block-title-color);
    margin-top: 0;
}

.articles__description {
    position: relative;
    transition: box-shadow 0.25s;
    display: flex;
    flex-flow: row nowrap;
    border-radius: var(--border-radius-small);
    padding: 45px 30px 45px 30px;
    margin: 0 0 24px 0;
    background: var(--main-card-bg-color);
    color: var(--card-item-primary-color);
    @media (max-width: 767px) {
        flex-flow: column nowrap;
    }
}

.articles__content * {
    box-sizing: border-box;
}

.articles__content {
    display: grid;
    grid-template-columns: minmax(350px, 413px) minmax(350px, 413px) minmax(350px, 413px);
    grid-gap: 30px 20px;

    @media (max-width: 1228px) {
        grid-template-columns: minmax(350px, 413px) minmax(350px, 413px);
    }

    @media (max-width: 767px) {
        grid-template-columns: minmax(300px, 413px);
    }
}

.article-card {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: var(--border-radius-small);
    padding: 57px 30px 69px 30px;
    background-color: var(--main-card-bg-color);
    color: var(--card-item-primary-color);
    height: 305px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
}

.article-container {
    display: flex;
    justify-content: center;
}

.article-card--page {
    box-shadow: none;
    background-color: var(--main-card-bg-color);
    border-radius: var(--border-radius-small);
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 800px;
    margin-bottom: 100px;
    @media (max-width: 769px) {
        padding: 50px 20px;
    }
}

.article-card__content-preview {
    max-width: fit-content;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: var(--main-font-secondary-color);
}

.article-card__title {
    text-decoration: none;
    min-height: 60px;
    font-size: 24px;
    line-height: 29px;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 5px;
    letter-spacing: 0.15px;

    & h4 {
        font-size: 24px;
        margin-top: 0;
        margin-bottom: 20px;
        color: var(--main-font-secondary-color);
        text-transform: uppercase;
    }

    & p {
        padding: 0 0 0 8px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        font-size: 14px;
        letter-spacing: 1.5px;
        opacity: 0.6;
    }

    a {
        text-decoration: none;
    }

    &:hover {
        color: var(--card-action-primary-color);

        & small {
            color: var(--card-item-primary-color);
        }
    }
}

.article-title {
    font-size: 32px;
    font-weight: 500;
    line-height: 39px;
}

.article-card__body {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 70%;
    height: 80px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    margin-top: -10px;

    p {
        color: var(--main-font-secondary-color);
        margin-top: 5px;
    }

}

.article-card__img {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 50px;
    background-color: var(--main-card-bg-color);
    color: var(--card-item-primary-color);
    height: 500px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    margin-bottom: 10px;
    border-radius: 50px;
    @media (max-width: 991px) {
        margin: 0;
    }
}

.article-card__img-content-preview {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.article-card__img__title{
    font-size: 50px;
    color: var(--main-font-secondary-color);
    @media (max-width: 769px) {
        font-size: 24px;
    }
}

.article-card__img__body p{
    font-size: 24px;
    color: var(--main-font-secondary-color);
    @media (max-width: 769px) {
        font-size: 16px;
    }
}

.article-card__body__text {
    margin: 0 25px;
    font-size: 20px;
    @media (max-width: 769px) {
        margin: 0;
    }
}

.article-card__show-more-button {
    display: inline-block;
    border-radius: 200px;
    padding: 13px 16px;
    outline: none;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    font-size: 16px;
    width: 150px;
    height: 45px;
    text-align: center;
    letter-spacing: 0.75px;
    text-decoration: none;
    background: var(--button-bg-color);
    color: var(--button-font-color);
    border: var(--border) var(--button-bg-color);
    cursor: pointer;

    &:hover {
        color: var(--button-font-hovered-color);
        background: var(--button-bg-hovered-color);
        cursor: pointer;
        border: var(--border) var(--button-bg-color);
    }
    &:active {
        background: var(--button-bg-active-color);
        color: var(--button-font-color);
        cursor: pointer;
    }

    @media (max-width: 420px) {
        width: 120px;
        height: 33px;
        padding: 8px 14px;
        line-height: 16px;
        font-size: 14px;
    }
}

.cart__button {
    display: inline-block;
    border: none;
    border-radius: 200px;
    padding: 8px 16px;
    outline: none;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    text-decoration: none;
    background: var(--card-action-primary-color);
    color: var(--card-action-secondary-color);

    &:hover {
        cursor: pointer;
        background: color-mod(var(--action-item-primary-color) blackness(+10%));
    }
}

.cart__button_disabled {
    opacity: 0.6;
}

.cart__button_disabled:hover {
    cursor: default;
    opacity: 0.6;
    background: var(--action-item-primary-color);
}

.cart__order-loader-wrapper {
    position: absolute;
    z-index: 150;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.order-loader {
    background: var(--card-bg-color);
    color: var(--card-item-primary-color);
    box-shadow: var(--shadow);
    border-radius: 4px;
    padding: 24px;
    display: flex;
    flex-flow: column nowrap;
    @media (max-width: 500px) {
        padding: 12px;
    }

    & h2 {
        @media (max-width: 350px) {
            font-size: 18px;
        }
    }
}

.order-loader__in-progress {
    color: color-mod(var(--card-item-primary-color) lightness(70%));
}

.order__number {
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    font-size: 14px;
    letter-spacing: 1.5px;

    & small {
        color: color-mod(var(--card-item-primary-color) lightness(50%));
        line-height: 22px;
        font-size: 16px;
        letter-spacing: 2.5px;
    }
}

.cart-content-wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    @media (max-width: 768px) {
        justify-content: space-around;
    }
}

.cart__empty {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.cart__empty-label {
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    font-size: 18px;
    letter-spacing: 1.5px;
}

.cart__products {
    padding: 8px;
    display: flex;
    flex-flow: column nowrap;
    min-width: 300px;
    flex-shrink: 1;
    @media (max-width: 400px) {
        padding: 4px;
    }
}

.cart__nav {
    width: 400px;
    padding-left: 70px;
    flex-grow: 0;

    @media (max-width: 1300px) {
        display: none;
    }
}

.cart__delivery-price {
    font-style: normal;
    font-weight: 200;
    line-height: 12px;
    font-size: 16px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    color: var(--header-item-color);
}

.cart__sum {
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    font-size: 22px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    color: var(--header-item-color);
}

.cart__product-card {
    background: var(--card-bg-color);
    color: var(--card-item-primary-color);
    box-shadow: var(--shadow);
    border-radius: 4px;
    display: flex;
    flex-flow: column nowrap;
    padding: 8px;
    margin: 8px 0;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.cart__clear-cart-button {
    display: inline-block;
    border: none;
    border-radius: 200px;
    margin: 8px 0;
    padding: 8px 16px 8px 16px;
    outline: none;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    text-decoration: none;
    background: var(--main-error-color);
    color: var(--card-action-secondary-color);

    &:hover {
        cursor: pointer;
        background: color-mod(var(--main-error-color) blackness(+10%));
    }
}

.cart__map_container {
    height: 580px;
    width: 100%;

    iframe {
        border: none
    }

    @media (max-width: 1100px) {
        height: 400px;
    }
}

.cart__delivery-full-street {
    display: flex;
}


.cart__pickup-full-street-select {
    flex: 1
}

.cart__pickup-full-street-button {
    margin-top: 8px;
    margin-left: 10px;
}


.delivery-type-preview {
    padding: 10px 20px 20px 20px;
}


.delivery-option-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 200px;
    margin: 8px;
    padding: 8px 16px 8px 16px;
    outline: none;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    text-decoration: none;
    background: var(--card-action-primary-color);
    color: var(--card-action-secondary-color);
    @media (max-width: 500px) {
        font-size: 10px;
        line-height: 12px;
        padding: 4px 8px;
    }

    &:hover {
        cursor: pointer;
        background: color-mod(var(--card-action-primary-color) blackness(+10%));
    }
}

.delivery-option-button.active {
    background: color-mod(var(--card-action-primary-color) blackness(+10%));
}

.delivery-option-button.disabled {
    pointer-events: none;
    background: color-mod(var(--card-action-primary-color) lightness(+25%));
}

.delivery-way {
    display: none;
}

.delivery-way.active {
    display: flex;
    flex-flow: column nowrap;
}

.delivery-form {
    display: flex;
    flex-flow: row wrap;
}

.delivery-form .feedback-form__input-wrapper {
    margin: 8px;
}

.delivery-payments {
    display: flex;
    @media (max-width: 770px) {
        div {
            flex-basis: 40%;
        }
    }
}

.delivery-type {
    display: flex;
    padding-bottom: 10px;
}

.delivery-payments {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 770px) {
        justify-content: space-between;

        div {
            flex-basis: 30%;
            margin-top: 20px;
            margin-left: 0;
        }
    }
}

.radio-wrapper {
    display: flex;
    flex-flow: row nowrap;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    font-size: 24px;
    letter-spacing: 1.5px;
    margin: 8px 0;

    & input {
        margin: 0 4px 0 0;
    }

    @media (max-width: 600px) {
        font-size: 16px
    }
    @media (max-width: 380px) {
        font-size: 12px
    }
}

.radio-wrapper:last-child {
    margin-left: 20px;
}

input[type='radio']:after {
    background: var(--footer-title-color);
    border: var(--footer-title-color);
}

input[type="radio"]:checked:after {
    background: var(--footer-title-color);
    border: var(--footer-title-color);
}

.radio-button {
    border: var(--footer-title-color);
    width: 24px;
    height: 24px;
}

.payment-result {
    display: flex;
    padding: 20px;
    margin-top: 40px;
    justify-content: space-around;

    @media (max-width: 1200px) {
        margin-bottom: 30px;
        flex-direction: column;


        .order-delivery {
            order: -1;
        }
    }
}

.payment-result {
    grid-row-gap: 30px;
    max-width: 1064px;
    background: var(--main-card-bg-color);
    box-sizing: border-box;
    background: var(--card-bg-color);
    border-radius: var(--border-radius-small);
    align-items: center;

    .payment-result__card-image {
        width: 500px;
        height: 500px;
    }

    @media (max-width: 1024px) {
        flex-direction: column;
        margin-bottom: 70px;
    }

    @media (max-width: 767px) {
        .payment-result__card-image {
            width: 326px;
            height: 326px;
        }
        .payment-result__card > div {
            justify-content: center;
        }
    }
}

.payment-result__card {
    max-width: 391px;

    .payment-result__card-header {
        font-size: 32px;
        font-weight: 500;
        margin: 0;
    }

    .payment-result__card-text {
        font-size: 20px;
        margin: 30px 0 50px 0;

        @media (max-width: 440px) {
            font-size: 16px;
            margin: 20px 0 56px 0;
        }
    }

    &-link {
        display: flex;
        text-decoration: none;
        max-width: 305px;
        margin-left: auto;

        @media (max-width: 440px) {
            margin: 0 auto;
            max-width: 100%;
        }

        .app-button {
            padding: 16px 8px;
            width: 100%;
        }
    }
}

.delivery-and-payment__delivery_prices {
    padding: 0 10px 10px 0;
}

.address-form__address-short-field {
    flex-basis: 22% !important;
}

.featured-categories {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 20px;
}

.featured-categories__title {
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 34px;
    letter-spacing: 0.25px;
    color: var(--block-title-color);
    margin-top: 0;
    margin-bottom: 20px;
}

.featured-categories__content {
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(305px, 1fr));
    grid-row-gap: 20px;
    grid-column-gap: 30px;

    @media (max-width: 1200px) {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

    @media (max-width: 768px) {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-row-gap: 12px;
        grid-column-gap: 12px;
    }

    @media (max-width: 486px) {
        grid-template-columns: repeat(2, 1fr);
    }

    &-mask {
        position: absolute;
        inset: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        background-color: var(--main-overlay-color);
    }

    &-text {
        font-weight: 700;
        font-size: 32px;
        line-height: 1.2;
        text-align: center;
        color: var(--main-card-bg-color);
        text-shadow: 0 2px 1px var(--cards-of-categories-font-shadow-color);
        margin: 0;

        @media (max-width: 486px) {
            font-size: 24px;
        }
    }
}

.featured-categories__catalog-link-button-wrapper {
    display: flex;
    justify-content: center;
    padding: 0 0 24px 0;
}

.category-card {
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    border-radius: 0 50px;
    overflow: hidden;
    transition: .4s ease;

    &:hover {
        transform: translateY(-4px);
        opacity: 0.7;
    }

    @media (max-width: 768px) {
        border-radius: 0 var(--border-radius-medium);
    }
}

.category-card--justify-to-start {
    margin-right: 20px;

    @media (max-width: 768px) {
        margin-right: 0;
    }
}

.category-card__image_placeholder {
    opacity: 0;
}

.category-card__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    text-align: center;
}

.category-card__title {
    margin: 0;
    font-weight: 700;
    line-height: 38px;
    font-size: 32px;
    color: var(--main-font-secondary-color);
}

.category-card__description {
    padding: 0 8px;
    line-height: 20px;
    font-size: 14px;
    letter-spacing: 0.25px;
    opacity: 0;
    transition: opacity 0.25s;
    color: var(--main-font-color);
    @media (max-width: 768px) {
        opacity: 0.7;
    }
    @media (max-width: 576px) {
        display: none;
    }
}

.catalog-link-button {
    display: inline-block;
    border: none;
    border-radius: 200px;
    padding: 8px 16px 8px 16px;
    outline: none;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    font-size: 18px;
    text-align: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    text-decoration: none;
    background: var(--card-action-primary-color);
    color: var(--card-action-secondary-color);

    &:hover {
        cursor: pointer;
        background: color-mod(var(--action-item-primary-color) blackness(+10%));
    }
}


/*Table order style*/
.table-order {
    background: var(--card-bg-color);
    color: var(--card-item-primary-color);
    box-shadow: var(--shadow);
    border-radius: 4px;
    display: flex;
    flex-flow: column nowrap;
    padding: 15px;
    @media (min-width: 600px) {
        min-width: 550px;
    }
}

.table-order__identify {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    border: 2px solid var(--card-action-primary-color);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 28px;
}

.table-order__identify label {
    position: absolute;
    top: -10px;
    padding: 0 2px 0 2px;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    font-size: 12px;
    letter-spacing: 0.4px;
    color: var(--card-action-primary-color);
    background: var(--card-bg-color);
}

.table-order__identify p {
    width: 100%;
    border: none;
    outline: none;
    font-style: normal;
    font-weight: normal;
    margin: 5px;
    line-height: 20px;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: var(--input-font-color);
    background: var(--card-bg-color);
}

.table-order__description p {
    margin: 5px;
}

/* order status */

.order-status {
    padding: 20px;
}

.order-status__content-wrapper {
    box-shadow: var(--shadow);
    border-radius: 4px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    padding: 20px;
    background: var(--card-bg-color);
    color: var(--card-item-primary-color);
}

.last-order {
    flex-basis: 100%;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    font-size: 18px;
    letter-spacing: 1.5px;
    color: var(--card-item-primary-color);
}

.last-order span {
    font-style: normal;
    font-weight: normal;
    line-height: 28px;
    font-size: 18px;
    letter-spacing: 0.5px;
    opacity: 0.6;
}

.order-wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    flex-basis: 100%;
    @media (max-width: 767px) {
        flex-flow: row wrap;
    }
}

.order-id {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    flex-basis: 49.9%;
    padding: 20px 0 0 0;
    @media (max-width: 320px) {
        flex-flow: row wrap;
    }
}

.order-id button {
    background: none;
    display: inline-block;
    border: none;
    border-radius: 200px;
    padding: 8px 16px 8px 16px;
    margin: 0 0 0 8px;
    outline: none;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    background: var(--card-action-primary-color);
    color: var(--card-action-secondary-color);
    @media (max-width: 320px) {
        margin: 10px 0 0 0;
    }
}

.order-id button:hover {
    background: color-mod(var(--card-action-primary-color) blackness(+10%));
}

.order-id label {
    position: absolute;
    top: 12px;
    left: 8px;
    padding: 0 2px 0 2px;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    font-size: 12px;
    letter-spacing: 0.4px;
    color: var(--card-item-secondary-color);
    background: var(--card-bg-color);
}

.order-id input {
    outline: none;
    border: 2px solid var(--card-item-secondary-color);
    box-sizing: border-box;
    border-radius: 200px;
    padding: 8px 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: var(--search-font-color);
    background: var(--card-bg-color);
    min-width: 200px;
    @media (max-width: 767px) {
        min-width: 50px;
    }

    @media (max-width: 320px) {
        min-width: 200px;
    }
}

.order-status {
    display: flex;
    flex-flow: column nowrap;
    flex-basis: 49.9%;
    padding: 20px 0 0 0;
    min-height: 130px;
}

.order-status h1 {
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-size: 20px;
    letter-spacing: 0.15px;
}

.order-status__none {
    padding: 0 0 0 8px;
    font-style: normal;
    font-weight: normal;
    line-height: 28px;
    font-size: 18px;
    letter-spacing: 0.5px;
    opacity: 0.6;
    color: var(--card-item-primary-color);
    @media (max-width: 320px) {
        width: 100%;
    }
}

.order-status__found {
    padding: 0 0 0 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    font-size: 18px;
    letter-spacing: 1.5px;
    color: var(--card-item-primary-color);
}

.order-status__found span {
    font-style: normal;
    font-weight: normal;
    line-height: 28px;
    font-size: 18px;
    letter-spacing: 0.5px;
    opacity: 0.6;
}

/* product */
.product {
    padding: 20px;
}

.product__content-wrapper {
    display: flex;
    flex-flow: column nowrap;
}

.product__header {
    display: flex;
    flex-flow: row nowrap;
}

.product__title {
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 34px;
    letter-spacing: 0.25px;
    color: var(--block-title-color);
    margin: 0;
}

.product__price {
    display: inline-block;
    border: none;
    border-radius: 200px;
    padding: 8px 16px 8px 16px;
    outline: none;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    font-size: 18px;
    text-align: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    background: var(--card-action-primary-color);
    color: var(--card-action-secondary-color);
}

.product__main-block {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    padding: 20px 0;
    @media (max-width: 576px) {
        flex-flow: column nowrap;
        justify-content: space-around;
    }
}

.product__image-wrapper {
    border-radius: var(--border-radius-small);
    overflow: hidden;
}

.product__image {
    height: 300px;
    @media (max-width: 374px) {
        height: 250px;
    }
}

.product__options-wrapper {
    display: flex;
    flex-flow: column nowrap;
    padding: 0 20px;
}

.product__options {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    padding: 0 0 16px 0;
    flex-direction: column;
    @media (max-width: 576px) {
        flex-flow: column nowrap;
        justify-content: space-around;
    }
}

.option-select {
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    margin: 4px;
    min-width: 225px;

    & label {
        font-style: normal;
        font-weight: normal;
        line-height: 24px;
        font-size: 14px;
    }
}

.option-select--custom {
    background-color: var(--card-bg-color);
    box-shadow: var(--shadow);
    border: none;
    border-radius: 4px;
}

.option-select__items div,
.option-select--custom {
    color: var(--card-item-primary-color);
    padding: 8px 16px;
    border: none;
    cursor: pointer;
}

.option-select__items {
    position: absolute;
    background-color: var(--card-bg-color);
    top: 95%;
    left: 0;
    right: 0;
    z-index: 99;
    box-shadow: var(--shadow);
    border-radius: 4px;
}

.option-select--hidden {
    display: none;
}

.option-select__items div:hover,
.same-as-selected {
    border-radius: 4px;
    background: color-mod(var(--card-bg-color) blackness(10%));
}

/* arrow */
.option-select--custom:after {
    position: absolute;
    content: "";
    top: 38px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: #000000 transparent transparent transparent;
}

.option-select--custom.select-arrow-active:after {
    border-color: transparent transparent #000000 transparent;
    top: 31px;
}

.select-arrow-active.option-select--custom {
    background: color-mod(var(--card-bg-color) blackness(10%));
}

.option-quantity {
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    margin: 4px;
    min-width: 225px;

    & label {
        font-style: normal;
        font-weight: normal;
        line-height: 24px;
        font-size: 14px;
    }
}

.option-quantity__actions {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    background-color: var(--card-bg-color);
    box-shadow: var(--shadow);
    border: none;
    border-radius: 4px;
    max-width: 70px;
    padding: 6px;

    & button {
        border: none;
        background: none;
        outline: none;
    }

    & button:hover {
        cursor: pointer;
        color: var(--card-action-primary-color);
        outline: none;
    }
}

.product__description {
    display: flex;
    flex-flow: column nowrap;
    font-style: normal;
    font-weight: normal;
    line-height: 25px;
    font-size: 15px;
    letter-spacing: 0.25px;
    color: var(--block-title-color);
    margin: 5px 0;

    & p {
        text-align: justify;
    }

    & a {
        text-decoration: none;
        color: var(--action-item-primary-color);
    }

    & a:hover {
        text-decoration: underline;
    }
}

.product__description-specs {
    display: flex;
    flex-flow: column nowrap;
}

.spec {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background: var(--card-bg-color);
    padding: 0 4px;
}

.spec:nth-child(2n + 1) {
    background: color-mod(var(--card-bg-color) blackness(10%));
}

.spec--title {
    padding: 8px 4px;
}

/* choose address */

.choose-address__container {
    display: flex;
    justify-content: center;
}

.choose-address__card {
    border-radius: var(--border-radius-small);
    padding: 42px 89px 42px 89px;
    background: var(--main-card-bg-color);
    max-width: 1000px;
    width: 100%;
    @media (max-width: 1100px) {
        padding: 20px 20px 20px 20px;
    }
    @media (max-width: 600px) {
        padding: 10px 15px 10px 15px;
    }
}

.choose-address__card-title {
    font-weight: 700;
    font-size: 24px;
}

.choose-address__card-radio {
    display: flex;
    padding: 15px 20px 25px 0;
    font-size: 24px !important;
    justify-content: space-between;
    @media (max-width: 700px) {
        padding: 15px 0 15px 0;
    }
}

.choose-address__card-radio-delivery {
    line-height: 38px;
    @media (max-width: 700px) {
        line-height: 20px;
        font-size: 16px;
        text-align: right;
        font-weight: bold;
        margin-left: 10px;
        display: flex;
        align-items: center;
    }
    @media (max-width: 380px) {
        font-size: 12px;
    }
}

.choose-address__card-radio-block {
    display: flex;

    div:nth-child(2) {
        margin-left: 15px;
    }

    @media (max-width: 400px) {
        font-size: 15px;
    }
}

.choose-address__card_map {
    width: 100%;
    height: 580px;

    iframe {
        border: none;
    }

    @media (max-width: 1100px) {
        height: 350px;
    }
}

.choose-address__card-delivery {
    display: flex;
    @media (max-width: 874px) {
        flex-direction: column;
        padding-bottom: 20px;
    }
}

.choose-address__card-delivery-input {
    flex: 1;
}

.choose-address__card-delivery-button {
    padding-left: 20px;
    @media (max-width: 874px) {
        justify-content: center;
        display: flex;
        padding-left: 0;
    }
}

.hidden-map {
    visibility: hidden;
    display: none;
}

.catalog__title {
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 34px;
    letter-spacing: 0.25px;
    color: var(--block-title-color);
    margin: 0 0 12px;
}

.categories-wrapper {
    overflow-x: scroll;
}

.categories-2wrapper {
    position: relative;
    background-color: var(--card-bg-color);
}

.categories-2wrapper:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 120px;
    background-color: var(--card-bg-color);
    z-index: 15;
}

.categories {
    list-style-type: none;
    display: flex;
    position: sticky;
    top: 90px;
    z-index: 1;
    flex-flow: column wrap;
    justify-content: flex-start;
    padding: 8px;
    margin: 0;
    box-shadow: var(--shadow);
    border: none;
    border-radius: 4px;
    background: var(--card-bg-color);
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    font-size: 14px;
    letter-spacing: 1.5px;
    min-width: 240px;
    background: var(--card-bg-color);
    @media (max-width: 768px) {
        display: none;
    }
    @media (max-width: 1200px) {
        top: 100px;
    }
}

.categories_top {
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 3px 8px;
    box-shadow: none;
    border-radius: 0;
    width: max-content;
    padding-right: 120px;

    .accordion-item {
        width: auto;
    }

    .accordion__header {
        padding: 4px 40px;
    }
}

.categories_scroll {
    cursor: grab;
}

.categories--top {
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 3px 8px;
    box-shadow: none;
    border-radius: 0;
    width: max-content;
    padding-right: 120px;

    .accordion-item {
        width: auto;
    }

    .accordion__header {
        padding: 4px 40px;
    }
}

.categories--scroll {
    cursor: grab;
}

.categories__item {
    text-decoration: none;
    color: var(--card-action-primary-color);
    padding: 8px;

    &:hover {
        color: var(--card-item-primary-color);
    }
}

.subcategories {
    position: relative;
    min-width: 200px;
    padding: 8px 0;
}

.subcategories:hover {
    background: color-mod(var(--card-bg-color) blackness(5%));
}

.subcategories .categories {
    display: none;
    position: absolute;
    top: 30px;
    left: 75%;
    margin-top: -1px;
    z-index: 15;
    box-shadow: var(--shadow);
}

.subcategories:nth-child(2n) {
    background: color-mod(var(--card-bg-color) blackness(2.5%));
}

.categories .subcategories:hover > .categories {
    display: flex;
    z-index: 10;
}

.accordion-item {
    display: flex;
    flex-flow: column nowrap;
    overflow: auto;
}

.accordion-item {
    background: var(--card-bg-color);
}

.accordion__header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 4px 16px;

    &:hover {
        background: color-mod(var(--card-bg-color) blackness(2.5%));
    }
}

.accordion-arrow {
    color: var(--card-action-primary-color);
    width: 24px;
    height: 24px;
}

.accordion__header .accordion-arrow {
    transform: rotate(90deg);
}

.accordion__header_open .accordion-arrow {
    transform: none;
}

.accordion-item__content {
    display: none;
    overflow: auto;
    padding: 4px;
}

.accordion-item__content_category {
    flex-flow: column nowrap;
    padding: 0 0 0 20px;
}

.menu-filter-combo {
    position: sticky;
    top: 0px;
    z-index: 15;
}

.filters {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    position: sticky;
    top: 0px;
    padding: 0 0 8px 0;
    z-index: 15;
    background: var(--main-bg-color);
    @media (max-width: 768px) {
        display: none;
    }
}

.filters--combo {
    /*align-self: flex-end;
    flex-grow: 2;*/
}

.filters__actions {
    display: flex;
    flex-flow: row nowrap;
}

.filter__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    border-radius: 200px;
    padding: 8px;
    margin: 8px 8px 8px 0;
    outline: none;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    text-decoration: none;
    background: var(--card-action-primary-color);
    color: var(--card-action-secondary-color);

    &:hover {
        cursor: pointer;
        background: color-mod(var(--card-action-primary-color) blackness(+10%));
    }
}

.dropmic--show > .filter__button {
    background: color-mod(var(--card-action-primary-color) blackness(+25%));
}

.filter__button--drop {
    background: var(--main-error-color);

    &:hover {
        background: color-mod(var(--main-error-color) blackness(+10%));
    }
}

.filter__button--apply {
    margin: 8px 8px 0 0;
}

.filter__dropdown {
    display: flex;
    flex-flow: column nowrap;
    border: none;
    z-index: 25;
    padding: 8px;
    min-height: 40px;
    box-shadow: var(--shadow);
    overflow-y: scroll;
    background: var(--dropdown-card-bg-color);
}

.filter__dropdown-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 8px 0 0 0;
    border-top: 1px solid color-mod(var(--dropdown-card-item-color) lightness(80%));
    background: var(--dropdown-card-bg-color);
}

.price-filter-wrapper {
    display: flex;
    flex-flow: column nowrap;
}

.price-filter {
    display: flex;
    flex-flow: row nowrap;
    margin: 4px 0 4px 0;
}

.price-filter__label {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 4px 0 0 4px;
    padding: 8px 16px 8px 16px;
    outline: none;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    font-size: 10px;
    text-align: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    background: var(--dropdown-card-button-bg-color);
    color: var(--dropdown-card-button-font-color);
}

.price-filter__input {
    border: 2px solid var(--dropdown-card-button-bg-color);
    border-radius: 0 4px 4px 0;
    box-sizing: border-box;
    padding: 6px;
    width: 80px;
    outline: none;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    @media (max-width: 768px) {
        width: 60px;
    }
}

.filter__title {
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    font-size: 10px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.filter__options {
    list-style-type: none;
    padding: 0 8px;
    margin: 4px 0;
    max-height: 80px;
    overflow: hidden;
    overflow-y: scroll;

    & li {
        padding: 4px 4px 4px 0;
    }

    & li:nth-child(2n) {
        background: color-mod(var(--dropdown-card-bg-color) blackness(5%));
    }
}

.filter__option {
    display: flex;
    align-items: center;
    padding: 4px 4px 4px 0;

    & .option-label {
        display: inline-block;
        margin: 0 0 0 8px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        font-size: 14px;
        letter-spacing: 1.5px;
    }

    & .option-label:hover {
        color: var(--dropdown-card-action-color);
    }

    &:hover .checkbox + label {
        background: color-mod(var(--dropdown-card-action-color) lightness(90%));
    }
}

.filters__item {
    padding: 8px 8px 8px 0;
}

.catalog__subheader {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
    @media (min-width: 769px) {
        display: none;
    }
}

.sidebar-accordion-item {
    display: flex;
    flex-flow: column nowrap;
    overflow: auto;
    background: var(--sidebar-bg-color);
    letter-spacing: 1px;
}

.sidebar-accordion__header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 4px 16px;
}

.sidebar-accordion__header .sidebar-accordion-arrow {
    transform: rotate(90deg);
}

.sidebar-accordion__header_open .sidebar-accordion-arrow {
    transform: none;
}

.sidebar-accordion-item__content {
    display: none;
    overflow: auto;
    padding: 4px 0px 4px 8px;
}

.sidebar-accordion-item__content_filters {
    flex-flow: column nowrap;
}

.filter-mobile {
    padding: 6px 0 8px 12px;
    display: flex;
    align-items: center;
    color: var(--dropdown-card-item-color);

    & > .option-label {
        line-height: 20px;
        font-size: 14px;
        letter-spacing: 1.5px;
    }
}

.sidebar-accordion-item__content_category {
    flex-flow: column nowrap;
}

.price-filter-mobile {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.price-filter-wrapper--mobile {
    width: 100%;
}

.filter__options--mobile {
    width: 100%;
}

.products-grid-wrapper {
    padding: 4px;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-column-gap: 20px;
    @media (min-width: 1200px) {
        grid-column-gap: 70px;
    }
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
}

/* products */
.products {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(305px, 1fr));
    grid-gap: 20px;

    @media (max-width: 1200px) {
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    }

    @media (max-width: 768px) {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }

    @media (max-width: 480px) {
        grid-template-columns: 1fr;
    }

    .card {
        display: flex;
        flex-direction: column;
        border-radius: 0 50px;
        overflow: hidden;
        transition: .4s ease;

        @media (max-width: 480px) {
            flex-direction: row;
            min-height: 133px;
            border-radius: var(--border-radius-small);
        }

        &:hover {
            transform: translateY(-4px);
        }

        &__image {
            flex-shrink: 0;
            position: relative;
            display: flex;
            width: 100%;
            cursor: pointer;

            @media (max-width: 480px) {
                max-width: 133px;
            }

            &-tag {
                position: absolute;
                top: 7px;
                left: 9px;
                z-index: 1;
            }

            img {
                width: 100%;
            }

            &_none {

            }
        }

        &__info {
            display: flex;
            flex-direction: column;
            grid-row-gap: 20px;
            background-color: var(--product-card-bg-color);
            box-sizing: border-box;
            width: 100%;
            flex-grow: 1;
            padding: 12px 20px 20px 20px;

            @media (max-width: 480px) {
                grid-row-gap: 4px;
                padding: 5px 4px 5px 10px;
            }

            &-title {
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: var(--product-card-main-text-color);
                margin: 0;

                @media (max-width: 480px) {
                    font-size: 16px;
                    line-height: 20px;
                    padding-right: 12px;
                    max-height: 40px;
                    overflow: hidden;
                }
            }

            &-description {
                font-weight: 300;
                font-size: 16px;
                line-height: 19px;
                color: var(--main-card-description-color);
                margin: 0;

                @media (max-width: 480px) {
                    font-size: 12px;
                    line-height: 15px;
                    max-height: 45px;
                    overflow: hidden;
                }
            }

            &-action {
                display: flex;
                align-items: center;
                justify-content: space-between;
                grid-column-gap: 12px;
                height: 40px;
                margin-top: auto;

                @media (max-width: 480px) {
                    height: 32px;
                    grid-column-gap: 4px;
                }

                .action-price {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    color: var(--product-card-main-text-color);
                    margin: 0;

                    @media (max-width: 480px) {
                        font-size: 12px;
                        line-height: 19px;
                    }
                }

                .app-button {
                    max-width: 152px;
                    width: 100%;
                    white-space: nowrap;

                    @media (max-width: 480px) {
                        font-size: 12px;
                        line-height: 1;
                        padding-top: 8px;
                        padding-bottom: 8px;
                        width: fit-content;
                    }
                }

                .app-counter {
                    height: 100%;

                    @media (max-width: 480px) {
                        max-width: 88px;
                    }
                }

                .action-link {
                    width: 100%;
                    max-width: 152px;

                    @media (max-width: 480px) {
                        width: fit-content;
                    }
                }
            }
        }
    }
}

.products__empty-page {
    color: var(--block-title-color);
}

.pagination__wrapper {
    display: flex;
    justify-content: center;
    margin-top: 24px;
}

.pagination {
    display: flex;
    flex-flow: row nowrap;
    list-style: none;
    padding: 0;
}

.pagination__page {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--card-bg-color);
    color: var(--card-item-primary-color);
    padding: 8px;
    margin: 8px;
    border-radius: 4px;
    text-decoration: none;
    transition: .4s ease;

    &:hover {
        color: var(--card-action-primary-color);
    }

    @media (max-width: 424px) {
        margin: 2px;
    }
}

.pagination__current {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--card-action-primary-color);
    color: var(--card-action-secondary-color);
    padding: 8px;
    margin: 8px;
    border-radius: 4px;
    text-decoration: none;

    @media (max-width: 424px) {
        margin: 2px;
    }
}

.pagination__button {
    border: none;
    border-radius: 0;
    align-items: center;
    display: flex;
    text-decoration: none;
    color: var(--card-item-primary-color);
    transition: .4s ease;

    &:hover {
        color: var(--card-action-primary-color);
    }
}

/* ui */

/* featured products */
.featured-products {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}

.featured-products__title {
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 34px;
    letter-spacing: 0.25px;
    color: var(--block-title-color);
    margin: 0 0 20px 0;
}

/* feedback form */


.feedback-form-padding {
    padding: 0 88px;
}

.feedback-form__checkbox {
    display: flex;
    flex-flow: row nowrap;
    padding: 0 0 16px 0;
}

.feedback-form__checkbox-label {
    width: 300px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    font-size: 14px;
    letter-spacing: 1.5px;
    color: var(--card-item-primary-color);

    & a {
        color: var(--card-action-primary-color);
        text-decoration: none;
    }

    & a:hover {
        text-decoration: underline;
    }
}

.feedback-form__checkbox-label.invalid a {
    color: var(--main-error-color);
}

.feedback-form__checkbox-content {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.feedback-form__output-wrapper_half {
    box-sizing: border-box;
    flex-basis: 40%;
    margin: 8px;
    padding: 10px;
    @media (max-width: 560px) {
        flex-basis: 90%;
    }
}

.feedback-form__title {
    font-size: 24px;
    font-weight: 500;
    color: var(--main-font-color);
    margin: 50px 0 30px 10px;
}

.delivery-time {
    display: flex;
    justify-content: space-between;

    @media (max-width: 600px) {
        flex-direction: column;
    }
}


.delivery-date {
    width: 45%;
    @media (max-width: 600px) {
        width: 100%;
    }
}
.delivery-time-title {
    margin-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.delivery-intervals-select {
    width: 100%;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    grid-area: select;
    border: none;
    font-size: 14px;
    background: none;
}

.delivery-intervals-select__triangle {
    width: 100%;
    display: grid;
    grid-template-areas: "select";
    align-items: center;
    position: relative;
    padding: 0 10px;
    border-radius: 5px;
    border: none;
}

.delivery-intervals-select__triangle:after {
    grid-area: select;
    content: "";
    justify-self: end;
    width: 0.8em;
    height: 0.5em;
    background-color: var(--main-font-color);
    opacity: .6;
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
}

.feedback-form__input-wrapper {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    border: var(--border) var(--main-border-color);
    box-sizing: border-box;
    border-radius: var(--border-radius-small);
    padding: 15px;
    margin-bottom: 28px;
}

.feedback-form__input-wrapper input {
    width: 100%;
    border: none;
    outline: none;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    font-size: 16px;
    letter-spacing: 0.25px;
    color: var(--main-font-color);
    background: none;
}

.feedback-form__input-wrapper .prompt-list {
    z-index: 9999;
    position: absolute;
    top: 46px;
    left: 2px;
    width: 100%;
    border-radius: var(--border-radius-small);
    border: var(--border) var(--main-border-color);
    background: var(--main-card-bg-color);
    padding: 15px 0 15px 15px;

    .prompt {
        line-height: 19px;
        font-size: 16px;
        border-radius: var(--border-radius-small);
        cursor: pointer;
        padding: 8px 0 8px 20px;
    }

    .prompt:hover {
        background: var(--footer-title-color);
    }

    div:last-child {
        border-bottom: black 1px solid;
    }
}

.feedback-form__input-wrapper input::placeholder {
    color: color-mod(var(--input-font-color) lightness(60%));
}

.feedback-form__input-wrapper label {
    position: absolute;
    top: -20px;
    padding: 0 2px 0 2px;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    font-size: 12px;
    letter-spacing: 0.4px;
    color: var(--input-default-color);
}

.feedback-form__input-wrapper .invalid {
    border: 2px solid var(--main-error-color);
}

.feedback-form__textarea-wrapper .invalid {
    border: 2px solid var(--main-error-color);
}

.feedback-form__input-wrapper span {
    position: absolute;
    bottom: -18px;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    font-size: 12px;
    letter-spacing: 0.4px;
    color: var(--main-error-color);
}

.feedback-form__input-wrapper.invalid {
    border: 2px solid var(--main-error-color);
}

.feedback-form__input-wrapper label.invalid {
    color: var(--main-error-color);
}

.feedback-form__textarea-wrapper {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    padding: 0 0 24px 0;
}

.feedback-form__textarea-wrapper textarea {
    outline: none;
    resize: none;
    border-radius: var(--border-radius-small);
    border: var(--border) var(--input-default-color);
    padding: 8px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: var(--input-font-color);
    background: var(--card-bg-color);
}

.feedback-form__textarea-wrapper textarea::placeholder {
    color: color-mod(var(--input-font-color) lightness(60%));
}

.feedback-form__textarea-wrapper label {
    position: absolute;
    top: -8px;
    left: 9px;
    padding: 0 2px 0 2px;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    font-size: 12px;
    letter-spacing: 0.4px;
    color: var(--card-action-primary-color);
    background: var(--card-bg-color);
}

.feedback-form__button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-bottom: 50px;

    @media (max-width: 400px) {
        width: 200px;
    }
}

.feedback-form__button-large {
    width: 100%;
}

/* product card */
.card__image-wrapper {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    cursor: pointer;
}

.card__image--none {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background: none;
    color: var(--card-font-color);
    border-radius: var(--border-radius-small);
}

.card__image--none::before {
    content: '\f82a';
    color: var(--main-secondary-color);
    padding: 95px;
    display: inline-block;
    font: normal normal normal 24px/1 "Material Design Icons";
    font-size: 70px;
    text-rendering: auto;
    line-height: inherit;
}

.card__image-label {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 11px 21px;
    background: var(--hit-bg-color);
    border: 2px solid var(--hit-border-color);
    border-radius: var(--border-radius-medium);
    font-weight: 900;
    font-size: 14px;
    line-height: 17px;
    color: var(--main-font-secondary-color);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.card__info {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    padding: 11px 20px;
    background: var(--main-card-bg-color);
}

.card__main-info {
    display: flex;
    justify-content: space-between;
}

.card__title {
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-size: 20px;
    line-height: 24px;
    color: var(--card-font-color);
}

.card__price {
    color: var(--card-font-color);
    min-width: 70px;
}

.card__description {
    font-style: normal;
    font-weight: 300;
    line-height: 19px;
    font-size: 16px;
    color: var(--card-font-color-70);
    min-height: 32px;
}

.card__description p {
    margin: 20px 0;
}

.card__actions {
    margin: auto 0 0 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    grid-column-gap: 2px;
    height: 40px;

    a {
        width: 100%;
    }

    .app-button {
        width: 100%;
        max-width: 152px;
        min-height: 100%;
    }

    .app-counter {
        height: 100%;
    }

    .card__price {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
    }
}


.card__button_disabled {
    opacity: 0.6;
}

.card__button_disabled:hover {
    cursor: default;
    opacity: 0.6;
    background: var(--button-bg-disabled-color);
    color: var(--button-font-disabled-color);
}

/* slider */

.admin-slider {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
    padding: 10px 0;
    border-bottom: 1px solid var(--main-secondary-color);
}

.admin-slider__title {
    margin: 0;
    line-height: 26px;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.admin-slider__link {
    position: relative;
    text-decoration: none;
    border-radius: 200px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-width: 100px;
    min-height: 34px;
    border: none;
    padding: 5px 20px;
    outline: none;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    background: var(--header-item-color);
    color: var(--header-bg-color);
}

.admin-slider__link:hover {
    background: color-mod(var(--header-item-color) blackness(+10%));
    cursor: pointer;
}

.slider-wrapper {
    position: relative;
    background: var(--main-color);
}

/* вынужденная мера, так как в нужом месте
стили изолированны и до них нет доступа из-за Vue */
.swiper-size_copied {
    height: 500px;
    width: 100%;

    @media (max-width: 1440px) {
        height: 500px;
    }

    @media (max-width: 1200px) {
        height: 400px;
        width: 100%;
    }

    @media (max-width: 990px) {
        height: 300px;
        width: 100%;
    }

    @media (max-width: 600px) {
        height: 230px;
    }

    @media (max-width: 414px) {
        height: 200px;
    }
}

/* вынужденная мера, так как в нужом месте
стили изолированны и до них нет доступа из-за Vue */
.swiper-size_copied {
    height: 500px;
    width: 100%;


    @media (max-width: 1440px) {
        height: 500px;
    }

    @media (max-width: 1200px) {
        height: 400px;
        width: 100%;
    }

    @media (max-width: 990px) {
        height: 300px;
        width: 100%;
    }

    @media (max-width: 600px) {
        height: 230px;
    }

    @media (max-width: 414px) {
        height: 200px;
    }
}

.slider__container {
    height: 100%;
}

.slide {
    position: relative;
}

.slide__shadow {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 22;
    background: var(--slide-overlay);
}

.prev,
.next {
    position: absolute;
    top: calc(50% - 36px / 2);
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    color: var(--slider-action-secondary-color);

    &:hover {
        color: var(--slider-action-primary-color);
    }
}

.prev {
    left: 0;
}

.next {
    right: 0;
}

.slide__img {
    width: 100%;
}

.slide__content {
    z-index: 23;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: flex-end;
    padding: 20px;
}

.slide__info {
    display: flex;
    flex-flow: column nowrap;
}

.slide__title {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 34px;
    letter-spacing: 0.25px;
    @media (max-width: 767px) {
        font-size: 24px;
    }
}

.slide__body {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    line-height: 28px;
    font-size: 18px;
    letter-spacing: 0.5px;
    @media (max-width: 767px) {
        line-height: 16px;
        font-size: 12px;
    }
}

.slide__button {
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    font-size: 20px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    color: var(--slider-action-primary-color);

    &:hover {
        color: var(--slider-action-secondary-color);
    }

    @media (max-width: 767px) {
        line-height: 18px;
        font-size: 16px;
    }
}

/* slide List */

.slide-list {
    position: relative;
    min-height: inherit;
    padding: 20px 0;
    @media (max-width: 425px) {
        padding: 20px 0;
    }
}

.slide-list__content {
    counter-reset: li;
    list-style: none;
    font: 14px "Trebuchet MS", "Lucida Sans";
    padding: 0;
    text-shadow: 0 1px 0 rgba(255, 255, 255, .5);
}

.slide-list__content li {
    display: flex;
    margin-bottom: 10px;
}

.slide-list__content a {
    position: relative;
    display: block;
    border-radius: 5px;
    width: 100%;
    padding: .4em .4em .4em .8em;
    margin: 0 0 0 2.5em;
    background: var(--header-item-color);
    color: var(--header-nav-item-color);
    text-decoration: none;
    transition: all .3s ease-out;
}

.slide-list__content a:hover {
    background: color-mod(var(--header-item-color) blackness(+10%))
}

.slide-list__content a:before {
    content: counter(li);
    counter-increment: li;
    position: absolute;
    border-radius: 5px;
    left: -2.5em;
    top: 50%;
    margin-top: -1em;
    background: var(--header-item-color);
    color: var(--header-nav-item-color);
    height: 2em;
    width: 2em;
    line-height: 2em;
    text-align: center;
    font-weight: bold;
}

.slide-list__content a:after {
    position: absolute;
    content: "";
    border: .5em solid transparent;
    left: -1em;
    top: 50%;
    margin-top: -.5em;
    transition: all .3s ease-out;
}

.slide-list__content a:hover:after {
    left: -.5em;
    border-left-color: var(--header-item-color);
}

.slide-list__create-new {
    @media (max-width: 480px) {
        padding-top: 10px;
        text-align: center;
    }
}

.slide-list__button-delete {
    position: relative;
    text-decoration: none;
    border-radius: 200px;
    display: flex;
    align-items: center;
    background: var(--header-item-color);
    color: var(--header-bg-color);
    padding: 0px 20px;
}

.slide-list__button-create {
    float: right;
    padding: 10px 20px;
    align-items: center;
    text-decoration: none;
    border-radius: 200px;
    background: var(--header-item-color);
    color: var(--header-bg-color);

    @media (max-width: 480px) {
        float: none;
    }
}

.slide-list__button-delete:hover, .slide-list__button-create:hover {
    background: color-mod(var(--header-item-color) blackness(+10%));
    cursor: pointer;
}

/* slide customization */

.slide-customization {
    position: relative;
    min-height: inherit;
    padding: 20px 0;
    @media (max-width: 425px) {
        padding: 20px 0;
    }
}

.slide-customization__title {
    font-family: Roboto;
    font-weight: normal;
    font-style: normal;
    font-size: 34px;
    letter-spacing: 0.25px;
    color: var(--main-font-color);
    margin-top: 0;
}

.slide-customization__image-wrapper {
    width: 100%;
}

.slide-customization__image-wrapper img {
    width: 100%;
    background-image: url('data:image/gif;base64,R0lGODlhCgAIAIABAN3d3f///yH5BAEAAAEALAAAAAAKAAgAAAINjAOnyJv2oJOrVXrzKQA7');
}

.slide-customization__options {
    display: flex;
    align-items: center;
    padding: 30px 0;
}

.slide-customization__customizationer {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.slide-customization__checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.slide-customization__lever {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--main-secondary-color);
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;

    &::before {
        position: absolute;
        border-radius: 50%;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: var(--main-card-bg-color);
        -webkit-transition: .4s;
        transition: .4s;
    }

    &:hover {
        background-color: color-mod(var(--main-secondary-color) blackness(+10%));
    }
}

.slide-customization__checkbox:checked + .slide-customization__lever {
    background-color: var(--main-color); /*#2196F3 */

    &:hover {
        background-color: color-mod(var(--main-color) blackness(+10%));
    }
}

.slide-customization__checkbox:focus + .slide-customization__lever {
    box-shadow: 0 0 1px var(--main-color); /*#2196F3 */
}

.slide-customization__checkbox:checked + .slide-customization__lever::before {
    transform: translateX(26px);
}

.slide-customization__select {
    width: 49%;
    height: 35px;
    font-size: 1.2rem;
}

.slide-customization__input-field {
    position: relative;
    margin-top: 25px;
    padding: 15px 0 0;
    width: 50%;
}

.slide-customization__textarea {
    font-family: inherit;
    width: 100%;
    border: 0;
    border-bottom: 2px solid var(--main-border-color);
    outline: 0;
    font-size: 1.3rem;
    padding: 7px 0;
}

.slide-customization__textarea::placeholder {
    color: transparent;
}

.slide-customization__textarea:placeholder-shown ~ .slide-customization__field-label {
    font-size: 1.3rem;
    cursor: text;
    top: 20px;
}

.slide-customization__field-label {
    position: absolute;
    top: -15px;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
}

.slide-customization__textarea:focus ~ .slide-customization__field-label {
    position: absolute;
    top: -15px;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    padding-bottom: 6px;
    font-weight: 400;
}

.slide-customization__action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0;
}

.slide-customization__button {
    position: relative;
    text-decoration: none;
    border-radius: 200px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: none;
    padding: 10px 20px;
    outline: none;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    background: var(--header-item-color);
    color: var(--header-bg-color);
}

.slide-customization__button:hover {
    background: color-mod(var(--header-item-color) blackness(+10%));
    cursor: pointer;
}


/* slider */
.swiper-slide {
    height: 100% !important;
}

.swiper-slide__block {
    display: flex;
    align-items: center;
}

.swiper-slide__img {
    height: 100% !important;
    width: 130% !important;
    border-radius: none !important;
    transform: translateX(10%);
}

.swiper-slide__description h3 {
    font-weight: 700;
    font-size: 80px;
    line-height: 97px;
    text-transform: uppercase;
}

.swiper-slide__description p {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    text-transform: lowercase;
}

.swiper-pagination-bullets {
    bottom: 30px;
}

.swiper-pagination-bullet {
    background-color: var(--main-card-bg-color) !important;
    opacity: 1;
    @media (min-width: 768px) {
        height: 16px;
        width: 16px;
    }
}
.swiper-pagination-bullet-active {
    background-color: var(--main-error-color) !important;
}

.toasted-outer .toasted.toasted-inner {
    border-radius: var(--border-radius-small);
    padding: 30px 50px;
    background: var(--main-footer-background-color);
}

/* SKELETON-ANIMATION */
/* 1) добавь новый контейнер с классом задающим необходимые высоту, ширину */
/* 2) добавь в этот же контейнер класс skeleton-animation */
/* 3) на html разметке по условию загрузки if-else сделай вывод нужного блока */
.skeleton-animation {
    position: relative;
    overflow: hidden;
    background-color: var(--skeleton-bg);

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 300%;
        height: 100%;
        background: var(--skeleton-gradient);
        animation: shimmer 2s infinite linear;
    }
}

@keyframes shimmer {
    0% {
        transform: translateX(-220%);
    }
    100% {
        transform: translateX(220%);
    }
}

/* feedback */

.feedback-title {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 10px;
}

.feedback-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 50px 0;
}

.feedback-img {
    margin: auto;
}

@media (max-width: 1200px) {
    .feedback-img {
        display: none;
    }
}

@media (max-width: 500px) {
    .feedback-form-padding {
        padding: 0 30px;
    }
}

@media (max-width: 380px) {
    .feedback-form-padding {
        padding: 0 15px;
    }
}

/* about */

.about-title {
    font-size: 32px;
    font-weight: 700;
    color: var(--main-font-color);
    margin-bottom: 20px;
}

.about-content {
    display: flex;
}

.about-description {
    width: 100%;
    text-align: left;
    margin-bottom: 20px;

    h1 {
        font-size: 24px;
        font-weight: 700;
    }

    p:nth-child(2) {
        margin-bottom: 50px;
    }
}

.about-company-images img {
    border-radius: var(--border-radius-medium);
    margin-left: 60px;
    margin-bottom: 50px;
    @media (max-width: 768px) {
        display: none;
    }
}

.about-cards {
    display: flex;
}

.about-card-content {
    border: var(--border) var(--main-font-color);
    background-color: var(--main-font-secondary-color);
    margin-right: 20px;
    margin-bottom: 100px;
    padding: 50px;
    border-radius: var(--border-radius-small);
    text-align: left;
    width: 100%;
}

.about-card-content-title {
    font-size: 24px;
    font-weight: 700;
    color: var(--main-font-color);
    padding-bottom: 20px;
}

.about-card-content-description {
    font-size: 20px;
    color: var(--main-font-color);
}

.about-subtitle {
    font-size: 24px;
    font-weight: 700;
    color: var(--main-font-color);
    margin-top: 100px;
    margin-bottom: 30px;
    margin-right: 20px;
}

@keyframes fadeEffect {

    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* UI BLOCK START */
/* частный случай app-input */
.app-input_approved {

    input {
        padding-right: 140px;
    }

    .app-input__status {
        position: absolute;
        right: 20px;
        bottom: 16px;
        display: flex;
        align-items: center;
        grid-column-gap: 5px;
        height: 19px;
        font-size: 14px;
        line-height: 17px;
        color: var(--profile-aprove-color);
        pointer-events: none;

        &-icon {
            width: 16px;
            height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            stroke: var(--profile-aprove-color);
            color: var(--input-error-color);
        }
    }
}

/* app-select */
.app-select {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 70px;

    &__label {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: var(--select-main-color);
        padding-left: 20px;
    }

    &__button {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        grid-column-gap: 8px;
        text-align: left;
        width: 100%;
        height: 50px;
        border: var(--border) var(--select-main-color);
        border-radius: var(--border-radius-small);
        padding: 15px 20px 15px 20px;
        line-height: 22px;
        color: var(--select-font-color);

        &-text {
            pointer-events: none;
        }

        &-icon {
            height: 20px;
            width: 20px;
            pointer-events: none;
            transform: rotate(0);
            transition: .3s ease;

            img {
                height: 100%;
                width: auto;
            }
        }

        &_active &-icon {
            transform: rotate(180deg);
            transition: .3s ease;
        }

        &:disabled {
            cursor: default;
        }
    }

    &__list {
        display: none;
        position: absolute;
        left: 0;
        max-height: 465px;
        overflow-y: auto;
        top: calc(100% + 3px);
        box-sizing: border-box;
        list-style-type: none;
        background-color: var(--select-list-bg-color);
        border-radius: var(--border-radius-small);
        border: var(--border) var(--select-main-color);
        width: 100%;
        margin: 0;
        padding: 0 0 22px 0;
        z-index: 1;

        &_visible {
            display: block;
        }

        &-item {
            position: relative;
            display: flex;
            align-items: center;
            grid-column-gap: 8px;
            margin: 0;
            padding: 18px 22px 10px 22px;
            cursor: pointer;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 22px;
                right: -22px;
                background-color: var(--select-main-color);
                height: 1px;
                width: calc(100% - 44px);
            }

            &:hover {
                background-color: var(--select-list-hovered-color);
            }

            &_active {
                background-image: var(--select-active-item-checkmark);
                background-position: right 22px top 18px;
                background-repeat: no-repeat;
            }
        }
    }
}

/* UI BLOCK END */

.toasted-outer .toasted.toasted-inner {
    border-radius: var(--border-radius-small);
    padding: 30px 50px;
    background: var(--main-footer-background-color);
}

/* SKELETON-ANIMATION */
/* 1) добавь новый контейнер с классом задающим необходимые высоту, ширину */
/* 2) добавь в этот же контейнер класс skeleton-animation */
/* 3) на html разметке по условию загрузки if-else сделай вывод нужного блока */
.skeleton-animation {
    position: relative;
    overflow: hidden;
    //background-color: var(--skeleton-bg);

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 300%;
        height: 100%;
        background: var(--skeleton-gradient);
        animation: shimmer 2s infinite linear;
    }
}

@keyframes shimmer {
    0% {
        transform: translateX(-220%);
    }
    100% {
        transform: translateX(220%);
    }
}

/* app-accordion */
.app-accordion {

    &__btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        grid-column-gap: 2px;
        width: 100%;
        padding: 6px;
        border: none;
        outline: none;
        transition: .4s ease;

        &-text {
            line-height: 1;
        }

        img, svg {
            height: 16px;
            width: 16px;
            transition: .4s ease;
        }
    }

    .is-active img, .is-active svg {
        transform: rotate(180deg);
    }

    &__content {
        overflow: hidden;
        transition: max-height .2s ease-out;
        margin: 0;
    }
}
/* UI BLOCK END */

.loyalty-program__card {
    background-color: var(--main-card-bg-color);
    border-radius: var(--border-radius-small);
    padding: 20px;
    margin-right: 20px;
    width: 305px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 1000px) {
        margin-right: 0;
        margin-top: 20px;
    }
    @media (max-width: 1200px) {
        padding: 15px;
    }
}

.loyalty-program__card:last-child {
    margin-right: 0;
}

.loyalty-program__card-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.loyalty-program__card-title {
    font-size: 24px;
}

.loyalty-program__card-percent {
    font-weight: bold;
    font-size: 24px;
    padding: 20px 0;
    @media (max-width: 1200px) {
        padding: 0;
    }

}

.loyalty-program__card-img {
    @media (max-width: 770px) {
        display: block;
    }
}

.loyalty-program__content-img {
    width: 50%;
    @media (max-width: 770px) {
        display: none;
    }
}

.loyalty-program__card-description {
    padding-bottom: 15px;
}

.loyalty-program__cards {
    display: flex;
    justify-content: space-around;
    @media (max-width: 1000px) {
        flex-direction: column;
        align-items: center;
    }
}

.loyalty-program__content {
    display: flex;
    align-items:center;
    @media (max-width: 770px) {
        justify-content: center;
    }
}

.loyalty-program__accordions li, ul{
    margin: 10px 0;
}

.loyalty-program__accordions {
    background-color: var(--main-card-bg-color);
    border-radius: var(--border-radius-small);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 30px;
    margin-top: 50px;
    margin-bottom: 50px;
    height: 100%;
    width: 600px;
}

.loyalty-program__accordion-title {
    display: flex;
    cursor: pointer;
    img {
        margin-left: 20px;
    }
}

.loyalty-program__accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: all 300ms ease;
}

.active .loyalty-program__accordion-arrow {
    transition: all 0.25s cubic-bezier(0.5, 0, 0.1, 1);
    transform: rotate(180deg);
}

.loyalty-program__card {
    background-color: var(--main-card-bg-color);
    border-radius: var(--border-radius-small);
    padding: 20px;
    margin-right: 20px;
    width: 305px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 1000px) {
        margin-right: 0;
        margin-top: 20px;
    }
    @media (max-width: 1200px) {
        padding: 15px;
    }
}

.loyalty-program__card:last-child {
    margin-right: 0;
}

.loyalty-program__card-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.loyalty-program__card-title {
    font-size: 24px;
}

.loyalty-program__card-percent {
    font-weight: bold;
    font-size: 24px;
    padding: 20px 0;
    @media (max-width: 1200px) {
        padding: 0;
    }

}

.loyalty-program__card-img {
    @media (max-width: 770px) {
        display: block;
    }
}

.loyalty-program__content-img {
    width: 50%;
    @media (max-width: 770px) {
        display: none;
    }
}

.loyalty-program__card-description {
    padding-bottom: 15px;
}

.loyalty-program__cards {
    display: flex;
    justify-content: space-around;
    @media (max-width: 1000px) {
        flex-direction: column;
        align-items: center;
    }
}

.loyalty-program__content {
    display: flex;
    align-items:center;
    @media (max-width: 770px) {
        justify-content: center;
    }
}

.loyalty-program__accordions li, ul{
    margin: 10px 0;
}

.loyalty-program__accordions {
    background-color: var(--main-card-bg-color);
    border-radius: var(--border-radius-small);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 30px;
    margin-top: 50px;
    margin-bottom: 50px;
    height: 100%;
    width: 600px;
}

.loyalty-program__accordion-title {
    display: flex;
    cursor: pointer;
    img {
        margin-left: 20px;
    }
}

.loyalty-program__accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: all 300ms ease;
}

.active .loyalty-program__accordion-arrow {
    transition: all 0.25s cubic-bezier(0.5, 0, 0.1, 1);
    transform: rotate(180deg);
}
