.app-product-slider {
    position: relative;
    display: flex;
    align-items: center;
    height: fit-content;

    &__skeleton {
        width: 100%;
        aspect-ratio: 1;
    }

    &__image {
        width: 100%;
        object-fit: cover;
    }

    &__pagination {
        position: absolute;
        bottom: 4px;
        left: 50%;
        transform: translate(-50%);
        display: flex;
        align-items: center;
        grid-column-gap: 5px;
        box-sizing: border-box;

        &-button {
            flex-shrink: 0;
            width: 10px;
            height: 10px;
            aspect-ratio: 1;
            border-radius: 50%;
            border: none;
            background-color: var(--main-bg-color);
            padding: 0;

            &.active {
                background-color: var(--main-active-button-color);
            }
        }
    }
}
