.vd-overlay__inner {
    background-color: var(--main-overlay-color) !important;
}

.vd-picker__table-days__wrapper {
    overflow: visible !important;

    .vd-picker__table-days {
        overflow: visible !important;
    }
}

.vd-menu__content {
    width: 325px !important;
    min-width: unset !important;
    max-width: unset !important;
    margin-top: 5px;
    border-radius: var(--border-radius-small) !important;
    border: var(--border) var(--input-default-color) !important;
    box-sizing: border-box;
}

.vd-menu__content .vd-picker {
    background-color: var(--profile-bg-content);
    border-radius: var(--border-radius-small);
}

.vd-picker__table-day__wrapper .vd-picker__table-day__effect {
    background-color: var(--datepicker-hover-color);
}

.vd-picker__table-day--selected .vd-picker__table-day__effect {
    background-color: var(--datepicker-active-color);
}

.vd-picker__table-days .vd-picker__table-day {
    overflow: visible;
}

.app-datepicker .app-input input {
    text-align: left;
}

@media (min-width: 425px) {

    .vd-picker {
        width: 325px !important;
    }

}

@media (max-width: 425px) {

    .vd-menu__content {
        border-radius: 15px 15px 0 0 !important;
        border: var(--border) var(--input-default-color) !important;
    }

    .vd-menu__content .vd-picker {
        border-radius: 15px 15px 0 0 !important;
    }
}
