.app-input {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 70px;

    input {
        width: 100%;
        height: 50px;
        padding: 15px 8px 16px 20px;
        border: var(--border) var(--input-default-color);
        background-color: transparent;
        border-radius: var(--border-radius-small);
        outline: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        transition: 0.5s ease;
        box-sizing: border-box;
        margin-top: 20px;

        &:focus {
            border: var(--border) var(--input-active-color);
        }
    }

    &__label {
        position: absolute;
        left: 0;
        top: 20px;
        padding: 15px 8px 16px 20px;
        transition: 0.5s ease;
        pointer-events: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        white-space: nowrap;
        width: fit-content;

        &.active {
            top: 0;
            color: var(--input-default-color);
            transform: translateX(20px);
            padding: 0;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
        }
    }

    &.error {

        input {
            border: var(--border) var(--input-error-color);
        }

        .app-input__error {
            color: var(--input-error-color);
            margin: 2px 0 0 12px;
            font-size: 12px;
            line-height: 1;
        }
    }

    &.disabled {

        input {
            background-color: var(--input-disabled-color);
            border: none;
        }

        .app-input__label {
            color: var(--input-disabled-inner-color);
        }
    }

    /* Стиль инпута с нижним подчеркиванием */
    &_underline {
        height: 64px;

        input {
            padding: 13px 8px 13px 0;
        }

        input, input:focus {
            border-top: none;
            border-right: none;
            border-left: none;
            border-bottom: 1px solid var(--input-default-color);
            border-radius: 0;
        }

        .app-input__label {
            padding: 15px 8px 16px 0;
            color: var(--main-font-color);
            font-size: 16px;
            line-height: 19px;

            &.active {
                transform: translateX(0);
            }
        }

        &.error {

            input {
                border-bottom: var(--border) var(--input-error-color);
                border-top: none;
                border-left: none;
                border-right: none;
            }

            .app-input__error {
                color: var(--input-error-color);
                margin: 2px 0 0 12px;
                font-size: 12px;
                line-height: 1;
            }
        }
    }
}
