.client-data {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__title {
        font-size: 32px;
        font-weight: 700;
        color: var(--main-font-color);
        line-height: 39px;
        margin: 0 0 30px;

        @media (max-width: 768px) {
            font-size: 24px;
            line-height: 29px;
        }
    }

    &__form {
        display: grid;
        background-color: var(--card-bg-color);
        width: 100%;
        padding: 50px 88px;
        border-radius: var(--border-radius-small);
        box-sizing: border-box;
        grid-row-gap: 30px;

        @media (max-width: 768px) {
            padding: 50px 20px;
        }

        .app-input, .app-select, .app-button {
            width: 100%;
        }

        .app-checkbox {
            width: fit-content;
            margin-left: 18px;
        }

        &-radio-list {
            display: flex;
            grid-column-gap: 20px;
            margin-left: 20px;

            @media (max-width: 768px) {
                margin-left: 0;
            }
        }

        &-wrap {

            &_double {
                display: grid;
                grid-template-columns: 1fr 1fr;
                align-items: end;
                grid-column-gap: 20px;
                grid-row-gap: 30px;

                @media (max-width: 768px) {
                    grid-template-columns: 1fr;
                }

                .app-checkbox {
                    margin-left: 20px;
                }

                .show-map-btn {
                    height: 50px;
                }
            }

            &_quadruple {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                align-items: center;
                grid-column-gap: 20px;
                grid-row-gap: 30px;

                @media (max-width: 768px) {
                    grid-template-columns: repeat(2, 1fr);
                }
            }
        }

        &-title {
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
            margin: 0;
            color: var(--main-font-color);
        }

        .scroll-container {
            display: flex;
            align-items: center;
            grid-column-gap: 20px;

            .payment-type-btn {
                cursor: pointer;
                width: 152px;
                height: 152px;
                padding: 15px 20px 15px 20px;
                border-radius: var(--border-radius-small);
                border: 2px solid var(--main-border-color);
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                grid-row-gap: 15px;
                flex-shrink: 0;
                text-align: left;
                transition: .2s ease;

                &:disabled {
                    background-color: var(--main-disabled-bg-color);
                    border: var(--border-bold) var(--main-disabled-bg-color);
                    cursor: default;
                }

                &:hover:not(:disabled), &:focus, &.active {
                    border: var(--border-bold) var(--main-aprove-color);
                }

                &__title {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: var(--main-font-color);
                    margin: 0;
                }

                &__subtitle {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 16px;
                    color: var(--main-border-color);
                }
            }
        }

        &-submit {
            width: 100%;
            text-align: right;

            .app-button {
                height: 50px;
            }

            &-approve {
                font-size: 14px;
                line-height: 17px;
                font-weight: 400;
                color: var(--main-border-color);
                margin:10px 0 0;
            }

            &-link {
                color: var(--main-font-primary-color);
                text-decoration: none;
                font-weight: 400;
            }
        }
    }
}
